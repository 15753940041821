<template>
  <div>
    <item-list
      :list="list"
      :listId="listId"
      :availableHeaders="headers"
      :refreshColumnDecoration="refreshColumnDecoration"
      :decorateColumnAction="decorateColumnAction || ((path) => $emit('decorateColumn', path))"
      externalPagination
      serverside
    >
      <template v-slot:amount="{ item }">
        <div v-if="addonTransactions" :set="pkg = getPackage({ currencyId: item.currencyId })">
          {{ item.amount / pkg.tokenAmount }} {{ localize({ array: pkg.name }) }}
        </div>
        <div v-else>
          {{formatAmount(applyCurrencyExchange(item.amount, item.currencyId))}} {{getCurrencyName(item.currencyId)}}
          <span class="font-weight-light ml-2" :set1="otherCurrencyId = currencies.find(c => c.id !== item.currencyId).id">
            {{formatAmount(applyCurrencyExchange(item.amount, otherCurrencyId))}} {{getCurrencyName(otherCurrencyId)}}
          </span>
        </div>
      </template>
      <template v-slot:paymentMethod="{ item }">
        <div v-if="item.type === transactionTypes.PAYMENT">
          {{
            item.paymentMethodId
              ? item.paymentMethod ? item.paymentMethod.name : null
              : roomCharge
                ? $t('payment_methods.room_charge')
                : null
          }}
        </div>
      </template>
      <template v-slot:appendActions="{ item }">
        <v-layout>
          <v-spacer />
          <button-menu v-if="item.serviceId === appServerServiceId && (isRefundable(item) || !item.orderId) && isCurrencyLegal(item.currencyId)">
            <template v-slot:content>
              <tooltip-button v-if="isRefundable(item)"
                :disabled="noShift"
                :disabledTooltip="!noShift"
                :tooltipText="$t('shifts.warnings.required')"
                :buttonClass="(!item.orderId || !addonTransactions) ? 'mb-2': ''"
                @click="refundTransaction(item)"
                :text="$t('actions.refund')"
                outlined
              />
              <v-btn v-if="!item.orderId" outlined @click="openReceipt(item)" :class="{ 'mb-2': !addonTransactions }">
                {{ $t('transactions.receipt') }}
              </v-btn>
              <tooltip-button v-if="!addonTransactions"
                :disabled="disconnectedPeripheralService || !selectedPrinter"
                :disabledTooltip="!(disconnectedPeripheralService || !selectedPrinter)"
                :tooltipText="disconnectedPeripheralService ? $t('peripherals.warnings.disconnected_peripheral_service') : $t('peripherals.warnings.printer_not_configured')"
                :loading="loading.printingTicketTransactionId === item.id"
                @click="printTicket(item.id)"
                icon="mdi-printer"
                :outlined="true"
                :isIcon="true"
              />
            </template>
          </button-menu>
        </v-layout>
      </template>
    </item-list>
    <refund-transaction-modal
      @completedAction="$emit('refund')"
      :triggerOpen="triggerOpenRefundModal"
      :originalTransaction="currentRefundTransaction"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { transactionTypes } from '@/config'
import ServerDirections from '@/config/server-directions'
import { isTransactionRefunded } from '@/utils/TransactionUtils'
import { TransactionRequests } from '@/services/transaction.requests'

import printerMixins from '@/mixins/PrinterPeripheralActions'
import peripheralMixins from '@/mixins/PeripheralSocket'
import localizationMixins from '@/mixins/Localization'
import pricingHelpers from '@/mixins/PricingHelpers'
import moneyFormat from '@/mixins/MoneyFormat'
import shiftMixins from '@/mixins/Shifts'

const transactionService = new TransactionRequests()

export default {
  props: {
    list: Array,
    listId: {
      type: String,
      required: true
    },
    refreshColumnDecoration: Date,
    addonTransactions: Boolean,
    roomCharge: Boolean,
    decorateColumnAction: String
  },
  data () {
    return {
      table: this.listId,
      headers: [
        { text: this.$t('fields.id'), path: 'id', show: false },
        { text: this.$t('fields.created'), path: 'created', show: true, isFullDate: true },
        { text: this.$t('fields.station'), path: 'station.name', show: true, willHaveValue: (item) => !!item.stationId },
        ...(this.addonTransactions ? [
          { text: this.$t('fields.items'), path: 'amount', show: true },
          { text: this.$t('fields.operator'), path: 'operator.name', show: false, willHaveValue: () => true, permission: 'LIST_OPERATORS' }
        ] : [
          { text: this.$t('fields.amount'), path: 'amount', show: true },
          // { text: this.$t('fields.reference'), path: 'order.reference', show: true, willHaveValue: (item) => !!item.orderId, permission: 'PAGINATE_ORDERS' },
          { text: this.$t('fields.type'), path: 'type', show: false, getValue: (item) => this.$t(`transactions.type.${item.amount < 0 ? 'refund.' : ''}${item.type}`) },
          { text: this.$t('fields.payment_method'), path: 'paymentMethod', show: false, permission: 'LIST_PAYMENT_METHODS' },
          { text: this.$t('fields.service'), path: 'service.name', show: false, willHaveValue: (item) => !!item.serviceId, permission: 'LIST_SERVICES' },
          { text: this.$t('fields.concept'), path: 'concept', show: false }
        ])
      ],
      transactionTypes,
      triggerOpenRefundModal: null,
      currentRefundTransaction: null,
      loading: {
        printingTicketTransactionId: null
      }
    }
  },
  computed: {
    ...mapState(['pagination']),
    ...mapState({
      disconnectedPeripheralService: state => state.peripherals.disconnectedPeripheralService,
      selectedPrinter: state => state.peripherals.selectedPrinter
    }),
    ...mapGetters({
      authToken: 'auth/authToken',
      getPackage: 'packages/getPackage',
      getCurrencyName: 'configuration/getCurrencyName',
      currencies: 'configuration/currencies',
      paymentMethods: 'configuration/paymentMethods',
      appServerServiceId: 'configuration/appServerServiceId',
      isCurrencyLegal: 'configuration/isCurrencyLegal'
    })
  },
  methods: {
    isRefundable (transaction) {
      return !(transaction.type === transactionTypes.PAYMENT && transaction.amount <= 0) && !isTransactionRefunded(transaction)
    },
    refundTransaction (transaction) {
      this.currentRefundTransaction = transaction
      this.triggerOpenRefundModal = new Date()
    },
    openReceipt (transaction) {
      const route = ServerDirections.POS_TRANSACTIONS_ID_TICKET.replace('{id}', transaction.id)
      window.open(`${route}?x-api-key=${this.authToken}`)
    },
    printTicket (transactionId) {
      this.loading.printingTicketTransactionId = transactionId
      transactionService.getTicket(transactionId).then((base64Pdf) => {
        this.sendPrinterAction(base64Pdf, 'application/pdf', () => {
          this.$store.commit('state/setMessage', { text: this.$t('peripherals.printer.success'), color: 'success' })
          this.loading.printingTicketTransactionId = null
        }, () => {
          this.$store.commit('state/setMessage', { text: this.$t('transactions.error.receipt'), color: 'error' })
          this.loading.printingTicketTransactionId = null
        })
      }).catch(() => {
        this.loading.printingTicketTransactionId = null
        this.$store.commit('state/setMessage', { text: this.$t('transactions.error.receipt'), color: 'error' })
      })
    },
  },
  components: {
    RefundTransactionModal: () => import('@/components/view-components/modals/RefundTransactionModal.vue'),
    TooltipButton: () => import('@/components/app-components/buttons/TooltipButton.vue'),
    ButtonMenu: () => import('@/components/app-components/buttons/ButtonMenu.vue'),
    ItemList: () => import('@/components/app-components/listing/ItemList.vue')
  },
  mixins: [
    localizationMixins,
    peripheralMixins,
    pricingHelpers,
    printerMixins,
    moneyFormat,
    shiftMixins
  ]
}
</script>
